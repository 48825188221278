import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { config, library } from '@fortawesome/fontawesome-svg-core';
// Import each package individually so they can be added to library
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../index.scss';

config.autoAddCss = false
library.add(fab, fas, far, fal, fat);

function MyApp({ Component, pageProps }) {
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
        </>
    );
}

export default appWithTranslation(MyApp);
